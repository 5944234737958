<script setup lang="ts">
const { t } = useI18n()

const emit = defineEmits(['update:modelValue', 'blur'])

// TODO: Typescript errors
const props = withDefaults(
  defineProps<{
    modelValue: boolean | boolean[] | null
    parentName: string
    propertyName?: string | number | null
    light?: boolean
    labelLeft?: string | null
    translatedLabelLeft?: string | null
    labelRight?: string | null
    translatedLabelRight?: string | null
    required?: boolean
    thickBorder?: boolean
  }>(),
  {
    modelValue: null,
    parentName: undefined,
    propertyName: null,
    light: false,
    labelLeft: null,
    translatedLabelLeft: null,
    labelRight: null,
    translatedLabelRight: null,
    required: false,
    thickBorder: false,
  }
)

const localValue = computed({
  get(): boolean | unknown[] {
    return props.modelValue
  },
  set(newValue: boolean | []) {
    emit('update:modelValue', newValue)
  },
})

const value = computed((): boolean => {
  if (Array.isArray(props.modelValue)) {
    return props.modelValue.includes(props.propertyName)
  }
  return props.modelValue as boolean
})

const id = computed((): string => {
  return props.parentName + '-' + props.propertyName
})

const checkboxElement = computed((): HTMLElement | null => {
  return document.getElementById(id.value)
})

function toggleCheckbox() {
  if (checkboxElement.value) {
    checkboxElement.value.click()
  }
}

const getLeftLabel = computed(() => {
  const translated =
    props.translatedLabelLeft ??
    (props.labelLeft === null ? null : t(props.labelLeft))
  if (translated === null) {
    return null
  }
  return props.required ? translated + ' *' : translated
})

const getRightLabel = computed(() => {
  const translated =
    props.translatedLabelRight ??
    (props.labelRight === null ? null : t(props.labelRight))
  if (translated === null) {
    return null
  }
  return props.required ? translated + ' *' : translated
})
</script>

<template>
  <div
    class="group mb-2 flex max-w-fit duration-700"
    :class="{ 'text-neutral-100': light }"
  >
    <slot name="label-left" :label-for="id">
      <label v-if="getLeftLabel" class="me-3 cursor-pointer" :for="id">
        {{ getLeftLabel }}
      </label>
    </slot>
    <div class="cursor-pointer" @click="toggleCheckbox">
      <UCheckbox
        :id="id"
        class="pointer-events-none"
        v-model="localValue"
        :value="propertyName"
        :name="propertyName?.toString()"
        :ui="{
          base:
            'h-5 w-5 cursor-pointer' +
            (value && !light ? ' checkbox-filter' : ''),
          color: 'text-transparent',
          background:
            'bg-transparent group-hover:bg-neutral-400 checked:group-hover:!bg-transparent',
          border:
            (thickBorder ? 'border-2 ' : 'border ') +
            (light ? '!border-white' : '!border-black'),
          rounded: '',
        }"
        @blur="emit('blur')"
      >
        <template v-if="getRightLabel" #label>
          <slot name="label-right" :label-for="id">
            <span class="cursor-pointer">
              {{ getRightLabel }}
            </span>
          </slot>
        </template>
      </UCheckbox>
    </div>
  </div>
</template>

<style>
.checkbox-filter {
  -webkit-filter: brightness(0) saturate(100%);
  filter: brightness(0) saturate(100%);
}
</style>
